<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-close route-name="site_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <input type="hidden" v-model="site.id">
                    <div class="form-group">
                      <div class="site-new-view__checkboxes">
                        <app-checkbox
                          v-model="site.enabled"
                          id="enabled"
                          :label="$t('site.enabled')"
                        >
                        </app-checkbox>
                        <app-checkbox
                          v-if="vlm"
                          v-model="site.production"
                          id="production-site"
                          :label="$t('site.production')"
                        >
                        </app-checkbox>
                      </div>
                    </div>
                    <app-input
                      v-model="site.name"
                      @blur="$v.site.name.$touch()"
                      :error="$v.site.name.$error"
                      id="site_title"
                      readonly
                      :label="$t('site.name')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.title"
                      @blur="$v.site.title.$touch()"
                      :error="$v.site.title.$error"
                      id="site_title"
                      :label="$t('site.title')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.shortTitle"
                      @blur="$v.site.shortTitle.$touch()"
                      :error="$v.site.shortTitle.$error"
                      id="site_shortTitle"
                      :label="$t('site.shortTitle')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.meta.title"
                      @blur="$v.site.meta.title.$touch()"
                      :error="$v.site.meta.title.$error"
                      id="site_meta_title"
                      :label="$t('site.meta_title')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.meta.description"
                      @blur="$v.site.meta.description.$touch()"
                      :error="$v.site.meta.description.$error"
                      id="site_meta_description"
                      :label="$t('site.meta_description')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.meta.keywords"
                      @blur="$v.site.meta.keywords.$touch()"
                      :error="$v.site.meta.keywords.$error"
                      id="site_meta_keywords"
                      :label="$t('site.meta_keywords')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.woodWing.id"
                      id="site_woodWing_id"
                      :label="$t('woodWing.id')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.woodWing.articleDraftStatus"
                      id="site_woodWing_articleDraftStatus"
                      :label="$t('woodWing.status.article_export')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.woodWing.articleReadyStatus"
                      id="site_woodWing_articleReadyStatus"
                      :label="$t('woodWing.status.article_import')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.woodWing.imageDraftStatus"
                      id="site_woodWing_imageDraftStatus"
                      :label="$t('woodWing.status.image_export')"
                    >
                    </app-input>
                    <app-input
                      v-model="site.woodWing.imageReadyStatus"
                      id="site_woodWing_imageReadyStatus"
                      :label="$t('woodWing.status.image_import')"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Input from '../../components/form/inputs/Input'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import SiteModel from '../../model/SiteModel'
import ButtonClose from '../../components/shared/ButtonClose'
import Checkbox from '../../components/form/Checkbox'
import { mapGetters } from 'vuex'

export default {
  name: 'SiteNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      site: this._.cloneDeep(SiteModel)
    }
  },
  computed: {
    ...mapGetters(['vlm'])
  },
  validations: {
    site: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      meta: {
        title: {
          minLength: minLength(3),
          maxLength: maxLength(250)
        },
        description: {
          minLength: minLength(3),
          maxLength: maxLength(250)
        },
        keywords: {
          minLength: minLength(3),
          maxLength: maxLength(250)
        }
      },
      shortTitle: {
        minLength: minLength(1),
        maxLength: maxLength(20)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appButtonClose: ButtonClose,
    appCheckbox: Checkbox
  }
}
</script>

<style lang="scss">
  .site-new-view {
    &__checkboxes {
      display: flex;
      gap: 1rem;
    }
  }
</style>
